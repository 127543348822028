<template>
  <v-app>
    <v-app-bar app color="white" clipped-left>
      <div class="d-flex align-center">
        <v-img
          max-width="36"
          src="/img/logo/mola_digital_logo.png"
        ></v-img>
        <v-spacer v-if="mini == false"></v-spacer>
        <v-btn
          @click.stop="openNav(!mini)"
          text
          small
          class="d-flex justify-start px-4"
          :ripple="false"
          style="min-width: auto"
        >
          <v-icon v-if="this.$vuetify.breakpoint.width <= 960 ? !isOpen : mini">mdi-menu</v-icon>
          <v-icon v-else>mdi-menu-open</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn text to="/">
        <span class="mr-2">Back to Mola Dashboard</span>
      </v-btn>
    </v-app-bar>
    <Navigation :isMini="mini" :isOpen="isOpen" @change="setMini" @open="setOpen" v-if="this.$vuetify.breakpoint.width > 960" />
    <v-main>
      <Navigation :isMini="mini" :isOpen="isOpen" @change="setMini" @open="setOpen" v-if="this.$vuetify.breakpoint.width <= 960" />
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import Navigation from "./components/shared/Navigation.vue";

export default {
  name: "App",
  components: {
    Navigation,
  },
  data: () => ({
    mini: false,
    isOpen: true,
  }),
  created(){
    const link = window.location.href;
    if(link.includes('token')){
      const linkBreakdown = link.split('=');
      const token = linkBreakdown[1];
      this.$store.dispatch('setEligible', token);
    }
   
  },
  methods: {
    openNav(value){
      if(this.$vuetify.breakpoint.width <= 960){
        this.setMini(false);
        this.setOpen(true);
      }
      else{
        this.setMini(value)
      }
    },
    setMini(value) {
      this.mini = value;
    },
    setOpen(value){
      this.isOpen = value;
    }
  },
};
</script>
<style scoped>
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

/deep/ .v-toolbar__content {
  padding: 4px 12px !important;
}
</style>
