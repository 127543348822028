export const menu = [
  { link: "/", title: "Home", icon: "mdi-home" },
  { link: "/ticket", title: "Ticket Sales", icon: "mdi-file-chart-outline" },
  { link: "/location", title: "By Location", icon: "mdi-chart-pie" },
  { link: "/timeperiod", title: "By Time Period", icon: "mdi-clock-start" },
  { link: "/traffic", title: "Website Traffic", icon: "mdi-chart-box-outline", hasChildren: true, 
    children: [
      { link: "/compare", title: "Event Comparison", icon: "mdi-chart-box" },
      { link: "/spender", title: "Top Spender", icon: "mdi-chart-box" },
      { link: "/purchase-summary", title: "Purchase Summary", icon: "mdi-chart-box" },
    ] 
  },
  { link: "/market", title: "Customer Market Report", icon: "mdi-chart-bar", },
  // { link: "/account/settings", title: "Settings", icon: "mdi-cog" },
];
